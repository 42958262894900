<template>
  <div class="hero-slider">
    <div
      class="hero-slider__wrapper"
      v-touch:swipe.left="nextSlide"
      v-touch:swipe.right="previewSlide"
    >
      <div
        :style="{ backgroundImage: `url(${require(`@/assets/images/slide-${current}.jpg`)})` }"
        class="hero-slider__slide"
      >
        <div class="hero-slider__limiter">
          <slot :name="`slide${current}`" />
          <Column class="hero-slider__navigation">
            <Row class="hero-slider__pagination" :justify="$viewport.mobile ? 'center' : ''">
              <div
                :class="index === current ? 'hero-slider__pagination-active' : ''"
                :key="slide.id"
                class="hero-slider__pagination-step"
                v-for="(slide, index) in slides"
                v-on:click="setCurrentSlide(index)"
              ></div>
            </Row>
            <Row align="center" justify="between">
              <Row
                class="hero-slider__buttons"
                :full="$viewport.mobile"
                align="center"
                wrap
              >
                <router-link to="/gastrocard">
                  <Button color="red" :full="$viewport.mobile">Гастрономическая карта</Button>
                </router-link>
                <a class="router-link-exact-active router-link-active"
                   href="../events_calendar.pdf?y=24" target="_blank">
                  <Button color="transparent" :full="$viewport.mobile">
                    Событийный календарь
                  </Button>
                </a>
                <!-- <router-link to="/quiz">
                  <Button color="red" :full="$viewport.mobile">
                    Квиз "Открой Томскую область"
                  </Button>
                </router-link> -->
                <!-- <router-link to="/quiz">
                  <Button color="red" :full="$viewport.mobile">
                    Квиз "Изумрудный город"
                  </Button>
                </router-link> -->
              </Row>
              <Row align="center" class="hero-slider__buttons" v-if="current !== 0"> </Row>
              <Row v-if="$viewport.desktop" class="hero-slider__buttons">
                <HeroSliderButton
                  v-on:click.native="previewSlide"
                  :params="{ suffix: 'preview' }"
                />
                <HeroSliderButton v-on:click.native="nextSlide" :params="{ suffix: 'next' }" />
              </Row>
            </Row>
          </Column>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroSliderButton from './HeroSliderButton.vue';

export default {
  name: 'HeroSlider',
  components: { HeroSliderButton },
  data() {
    return {
      slides: [],
      current: 0,
      slideChangingInterval: null,
    };
  },
  mounted() {
    this.initSliderInterval();

    Object.keys(this.$slots).forEach((item, index) => {
      this.slides = [...this.slides, index];
    });
  },
  beforeDestroy() {
    this.clearSliderInterval();
  },
  methods: {
    initSliderInterval(timeout = 5000) {
      this.slideChangingInterval = setInterval(() => {
        this.current = this.current < this.slides.length ? this.current + 1 : 0;
      }, timeout);
    },
    clearSliderInterval() {
      clearInterval(this.slideChangingInterval);
      this.slideChangingInterval = null;
    },
    nextSlide() {
      this.clearSliderInterval();
      this.initSliderInterval(10000);

      this.current = this.current < this.slides.length ? this.current + 1 : 0;
    },
    previewSlide() {
      this.clearSliderInterval();
      this.initSliderInterval(10000);

      this.current = this.current > 0 ? this.current - 1 : this.slides.length;
    },
    setCurrentSlide(index) {
      this.current = index;
    },
  },
};
</script>
